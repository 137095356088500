@font-face {
  font-family: 'Logo';
  font-weight: normal;
  src: url(../public/fonts/Bonche-Light.otf);
}

@font-face {
  font-family: Header;
  src: url(../public/fonts/XillianRegular.otf);
}

@font-face {
  font-family: Primary;
  src: url(../public/fonts/Geomatrix.ttf);
}

body {
  background-color: white;
}

.marker-label-orange {
  background-color: #ffe0a3;
  border-radius: 32px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 2px;
}

.marker-label-blue {
  background-color: #e0eeff;
  border-radius: 32px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 2px;
}

/* Text field */

.text-field-container {
  display: flex;
  flex: 0;
  flex-direction: column;

}
.text-field-input {
  outline: none;
  font-size: 16px;
  border: 0; 
  padding: 7px 0; 
  border-bottom: 1px solid #ccc;
}

.text-field-input ~ .focus-border {
  width: 0;
  height: 2px;
  background-color: #4caf50;
  transition: 0.4s;
  margin-top: -2px;
}

.text-field-input:focus ~ .focus-border {
  width: 100%;
  transition: 0.4s;
}
